//
// user.scss
// Use this to write your custom SCSS
//

/*****************
* Navigation
******************/

// Allow active nav items to still be clicked
.nav-link.active:not([data-bs-toggle='dropdown']) {
  pointer-events: auto;
  cursor: pointer;
}

/*****************
* Benchmark card hover effects
******************/
// .card {
//   transition:
//     background-color 0.3s,
//     box-shadow 0.3s;
// }

// .card:hover {
//   background-color: rgba(114, 103, 139, 0.05);
//   /* Adjust the color and opacity as needed */
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
// }

.btn-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
}

.bg-light-grey {
  background-color: #f9f9f9;
}

// .top-border-below {
//   border-top: 3px solid #f03e3e;
// }

// .top-border-at {
//   border-top: 3px solid #ffdd00;
// }

// .top-border-above {
//   border-top: 3px solid #30b32d;
// }

/*****************
* Custom form styles
******************/

.highlight-field {
  border-width: 2px;
  /* Subtle green border */
  border-color: $highlight-subtle-green;
  /* Increase the border width */
  transition:
    border-color 0.3s ease,
    border-width 0.3s ease;
}

.highlight-field:focus {
  border-width: 2px;
  /* Ensure the border width remains consistent */
  /* Subtle green border */
  border-color: #b5d6a7;
}

.gauge-border {
  border: 2px solid $highlight-subtle-green;
  border-radius: 3px;
}

.bg-card-hover {
  background-color: rgba(114, 103, 139, 0.05);
}

// .highlight-field {
//   background-color: #d0e2ff;
//   /* Light blue background */
//   border-color: #a9c4ff;
//   /* Subtle blue border */
//   transition: background-color 0.3s ease, border-color 0.3s ease;
// }

// .highlight-field:focus {
//   background-color: #adcff9;
//   /* Slightly darker light blue when focused */
//   border-color: #8ab6f2;
//   /* Subtle blue border */
// }

// .highlight-field {
//   // background-color: #d4edda;
//   /* Light green background */
//   border-color: #c3e6cb;
//   /* Subtle green border */
//   transition: background-color 0.3s ease, border-color 0.3s ease;
// }

// .highlight-field:focus {
//   // background-color: #c8e6c9;
//   /* Slightly darker light green when focused */
//   border-color: #b5d6a7;
//   /* Subtle green border */
// }

// .highlight-field {
//   background-color: #F3E5F5;
//   /* Light purple background */
//   border-color: #D1C4E9;
//   /* Subtle purple border */
//   transition: background-color 0.3s ease, border-color 0.3s ease;
// }

// .highlight-field:focus {
//   background-color: #E1BEE7;
//   /* Slightly darker light purple when focused */
//   border-color: #B39DDB;
//   /* Subtle purple border */
// }
.xyz {
  background-color: rgba(114, 103, 139, 0.05);
}

/* Add this CSS to hide elements with x-cloak until Alpine.js initializes */
[x-cloak] {
  display: none !important;
}

.bg-archived {
  background-color: #ececec;
}

// h1,
// .h1,
// h2,
// .h2,
// h3,
// .h3,
// h4,
// .h4,
// h5,
// .h5,
// h6,
// .h6 {
//   color: var(--#{$prefix}body-color);
// }
.body-color {
  color: $body-color;
}

a.nav-link,
span.nav-link,
#mobile-sidebar a {
  color: $body-color;

  &.active {
    color: white;
  }
}

// .nav-item.sector {
//   .nav-link.active {
//     // background-color: $info-bg-subtle;
//     border: 1px solid $primary-border-subtle;
//     border-radius: 0.375rem; // This matches the Bootstrap `rounded-1` class (6px).
//     padding: 0.4rem 0.75rem; // Add padding to ensure consistent spacing inside the rounded corners.
//   }
// }

// .separator {
//   width: 1px;
//   height: 25px;
//   background-color: #ccc;
// }

.step-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  /* Adjust for size */
  height: 32px;
  /* Adjust for size */
  background-color: var(--bs-primary);
  /* Bootstrap primary color */
  color: $primary;
  font-size: 1rem;
  /* Adjust for text size */
  font-weight: bold;
  border-radius: 50%;
  /* Makes it circular */
  text-align: center;
}

/* Main sticky navigation */

html,
body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
}

.secondary-nav {
  background-color: #e8e7fb;
  // background-color: #e6ccff;
  // background-color: rgba($primary, 0.3);
  // background-color: #C84BF1;
  // background-color: #e9a6ff;
  // background-color: #ad57c9d2;
  height: 34px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  min-height: 34px;
}

.main-nav {
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1040;
}

// .footer {
//   background-color: #d4edda;
//   padding: 5rem;
//   text-align: center;
// }

/* Custom logo styles */
.logo {
  max-width: 280px;
  /* Full size for larger screens */
  height: auto;
  /* Maintain aspect ratio */
}

/* Adjust logo size for medium screens (e.g., iPad landscape) */
@media only screen and (max-width: 1120px) {
  .logo {
    max-width: 200px;
    /* Smaller size for medium screens */
  }
}

// /* New breakpoint for screens between 768px and 1024px */
// @media only screen and (max-width: 1024px) {
//   .logo {
//     max-width: 180px; /* Intermediate size */
//   }
// }

/* Adjust logo size for small screens (e.g., mobile phones) */
@media only screen and (max-width: 990px) {
  .logo {
    max-width: 220px;
    /* Even smaller size for small screens */
  }
}
