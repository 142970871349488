//
// user-variables.scss
// Use this to override Bootstrap and Silicon variables
//

// Example of a variable override to change Silicon background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #f5f6f8;
// $footer-bg: #39444a;
// $form-bg: #f5f6f8;
$highlight-subtle-green: #c3e6cb;
